/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import '../../style/profile.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { InlineWidget } from 'react-calendly'
import DatePicker from 'react-datepicker'
import {
  startOfDay,
  endOfDay,
  addMinutes,
  setHours,
  setMinutes,
} from 'date-fns'
import {
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  MenuItem,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import ProfileCard from '../../components/profileCard/ProfileCard'
import MiniNav from '../../components/miniNav/MiniNav'
import ReviewCard from '../../components/reviewCard/ReviewCard'
import About from '../../components/about/About'
// import BookingCard from "../components/bookingCard/BookingCard"
import Seo from '../../components/seo'
import PageNav from '../../components/homeNav/PageNav'
import Logo from '../../images/Afyabook.svg'
import 'react-datepicker/dist/react-datepicker.css'
import useAuthState from '../../stores/auth'
import 'react-toastify/dist/ReactToastify.css'
import useCountdownTimer from '../../hooks/useCountdownTimer'
import Footer from "../../components/homeNav/footer/Footer"

function AppointmentItem({ appointment }) {
  const { formattedRemainingTime } = useCountdownTimer(
    new Date(appointment.date),
  )
  return (
    <ListItem>
      <ListItemText>{formattedRemainingTime}</ListItemText>
      <ListItemText>{appointment.type}</ListItemText>
      {appointment.type === 'online' && (
        <Button onClick={() => navigate(`/meeting/${appointment._id}`)}>
          view
        </Button>
      )}
    </ListItem>
  )
}

export default function Doctor({ params }) {
  const { getUserId } = useAuthState()

  const { doctorId } = params
  const [loading, setLoading] = useState(false)

  const [doctor, setDoctor] = useState({})

  const fetchDoctor = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/doctors/${doctorId}`,
      )
      setDoctor(result.data)
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: [doctorId].js:29 ~ fetchDoctor ~ err:', err)
      setLoading(false)
      const { message } = err.response.data
      toast.error(message)
    }
  }

  const types = ['online', 'office', 'home']

  const [selectedDate, setSelectedDate] = useState(null)
  const [notes, setNotes] = useState('')
  const [type, setType] = useState(null)

  const [appointments, setAppointments] = useState([])

  const [showNewAppointment, setShowNewAppointment] = useState(false)

  const availabilityData = doctor?.availability || []

  const fetchUserAppointments = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${getUserId()}/appointments`,
      )
      const { data } = result.data

      const currentDoctorAppointments = data.filter(
        appointment => appointment.staff._id === doctorId,
      )
      setAppointments(currentDoctorAppointments)
      setLoading(false)
    } catch (err) {
      console.log(
        '🚀 ~ file: [doctorId].js:81 ~ fetchUserAppointments ~ err:',
        err,
      )
      setLoading(false)
      const { message } = err.response.data
      toast.error(message)
    }
  }

  useEffect(() => {
    fetchDoctor()
    fetchUserAppointments()
  }, [])

  const disableUnavailableDays = date => {
    const chosenDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    )

    const isDateAvailable = availabilityData.some(availabilityItem => {
      const availabilityStart = new Date(availabilityItem.range.start)
      const availabilityEnd = new Date(availabilityItem.range.end)

      availabilityStart.setHours(0, 0, 0, 0)
      availabilityEnd.setHours(0, 0, 0, 0)

      // Check if chosenDate is within the availability range
      if (chosenDate >= availabilityStart && chosenDate <= availabilityEnd) {
        // Check if the day of the week is in the schedule
        const selectedDayOfWeek = chosenDate.toLocaleDateString('en-US', {
          weekday: 'long',
        })
        const isDayInSchedule = availabilityItem.schedule.some(
          day => day.dayOfWeek === selectedDayOfWeek,
        )

        return isDayInSchedule
      }

      return false
    })

    return isDateAvailable
  }
  const [availabilityTimeData, setAvailabilityTimeData] = useState([])

  const handleDateChange = date => {
    setSelectedDate(date)

    // Get the selected day of the week
    const selectedDayOfWeek = date.toLocaleDateString('en-US', {
      weekday: 'long',
    })

    let availabilityItem = {}
    availabilityData.forEach(item => {
      const matchingScheduleItems = item.schedule.find(
        scheduleItem => scheduleItem.dayOfWeek === selectedDayOfWeek,
      )

      if (matchingScheduleItems) {
        availabilityItem = matchingScheduleItems
      }
    })

    if (availabilityItem) {
      setAvailabilityTimeData(availabilityItem.hours)
    } else {
      setAvailabilityTimeData([])
    }
  }
  const [selectedTime, setSelectedTime] = useState(null)

  const handleTimeChange = newTime => {
    setSelectedTime(newTime)
  }

  const filterTime = time => {
    const hour = time.getHours().toString()
    return availabilityTimeData.includes(hour)
  }

  const schedule = async () => {
    try {
      const meeting = {
        date: selectedDate,
        notes,
        userId: getUserId(),
        staffId: doctorId,
        type,
      }
      await axios.post(`${process.env.GATSBY_API_URL}/appointments`, {
        ...meeting,
      })
      toast.success('Appointment scheduled.')
      setShowNewAppointment(false)
      setNotes('')
      fetchUserAppointments()
    } catch (error) {
      const { message } = error.response.data
      toast.error(message)
    }
  }

  return (
    <div>
      <Seo
        title="Consult on medication therapy | Afyabook"
        description="Need more information on your medication use, side effects, medication therapy management and remote patient monitoring? Consult Dr. Ibrahim Okich"
        img={Logo}
        keywords="medication therapy management, medication side effects, medication use, remote patient monitoring, medication advice, pharmacist, clinical pharmacist, pharmacy"
        siteUrl="https://www.afyabook.com/Profile"
        canonical="https://www.afyabook.com/Profile"
      ></Seo> 
      <PageNav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div className="row justify-content-evenly">
              <div className="col-10">
                <ProfileCard doctor={doctor} />
                 <a href="#appointmentsSection">
                <button className="siCheckButton">Book Appointment</button>
                </a> 
                 {/* <MiniNav />  */}
              </div>
              <div className="col-12">
                 {/* <ReviewCard />  */}
                <About doctor={doctor} />
              </div>
              <div id="appointmentsSection">
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Grid item>
                    <Typography variant="h5">Appointments</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      type="button"
                      variant="text"
                      onClick={() => setShowNewAppointment(true)}
                      style={{ backgroundColor: 'blue', color: 'white', padding: '0.5em', marginLeft:'10px' }}

                    >
                      New
                    </Button>
                  </Grid>
                </Grid>

                {showNewAppointment && (
                  <Grid container spacing={2} justifyContent="space-between">
                    <Card
                      variant="outlined"
                      style={{ margin: '10px', padding: '10px' }}
                    >
                      <Grid container item xs={12} spacing={2}>
                        <Grid item>
                          <p>Date:</p>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            required
                            selected={selectedDate}
                            onChange={handleDateChange}
                            filterDate={disableUnavailableDays}
                            minDate={new Date()}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item>
                          <p>Time:</p>
                        </Grid>
                        <Grid item>
                          <DatePicker
                            required
                            selected={selectedTime}
                            onChange={handleTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            dateFormat="h:mm aa"
                            filterTime={filterTime}
                            label="time"
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item>
                          <p>Type:</p>
                        </Grid>
                        <Grid item>
                          <TextField
                            required
                            variant="outlined"
                            margin="normal"
                            value={type}
                            onChange={e => setType(e.target.value)}
                            select
                          >
                            {types.map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={2}>
                        <Grid item>
                          <p>Notes:</p>
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            multiline
                            rows={4}
                            onChange={e => setNotes(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          <Button
                            type="button"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setShowNewAppointment(!showNewAppointment)
                              setNotes('')
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            color="primary"
                            type="button"
                            variant="contained"
                            disableElevation
                            onClick={() => schedule()}
                          >
                            Schedule
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}

                <List>
                  {appointments.length ? (
                    appointments.map(appointment => (
                      <AppointmentItem
                        key={appointment._id}
                        appointment={appointment}
                      />
                    ))
                  ) : (
                    <ListItemText>No appointments</ListItemText>
                  )}
                </List>
              </div> 
               {/* <InlineWidget
                url="https://calendly.com/tripleaim"
                styles={{
                  height: '1000px',
                  width: '100%',
                }}
              /> */} 
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
